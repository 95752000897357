import { ThemeProvider, StyleReset, Div, Container, Row, Col, Text, Button, Anchor } from "atomize"
import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Footer from "../components/footer"
import "../settings/index.css"
import Header from "../components/header"
import SEO from "../components/seo"
import CoverWave from "../images/assets/w-come-funziona.svg"
import SubheaderText from "../components/SubheaderText"
import BoldText from "../components/BoldText"
import HeadingText from "../components/HeadingText"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBox, faBreadSlice, faExclamation, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import TitleText from "../components/TitleText"

let config = require("../settings/config")

const theme = config.theme
const url = config.url

const MemberEntry = props => {
    return (
        <>
            <Row p={{ t: { xs: "3rem", lg: "4rem" } }} align="center">
                <Col
                    size={{ xs: "12", md: "5" }}
                    order={props.align === "right" && { xs: 1, md: 2 }}
                    textAlign="center"
                    pos="relative"
                >
                    {props.img}
                </Col>
                <Col
                    size={{ xs: "12", md: "7" }}
                    style={{ margin: "auto" }}
                    order={props.align === "right" && { xs: 2, md: 1 }}
                >
                    <Div p={{ x: { md: "1rem" } }}>
                        <TitleText m={{ t: { xs: "1rem", md: "0" } }}>{props.name}</TitleText>
                        <TitleText textWeight="normal">{props.role}</TitleText>
                        <SubheaderText m={{ t: "1rem" }}>{props.description}</SubheaderText>
                    </Div>
                </Col>
            </Row>
        </>
    )
}

const Team = () => {
    return (
        <ThemeProvider theme={theme}>
            <StyleReset />

            <Header />
            <SEO
                title="Il team dell'app antispreco italiana"
                description="Ci presentiamo! Guarda quanto è brutto il team di Squiseat"
                pathname="obiettivi"
            />
            <Div tag="main" style={{ position: "relative", overflow: "hidden" }}>
                <Div
                    tag="section"
                    pos="relative"
                    bg="accent_ulight"
                    zIndex="1"
                    p={{
                        t: {
                            xs: "1rem",
                            sm: "2rem",
                            lg: "8rem",
                            xl: "10rem",
                        },
                    }}
                >
                    <Container>
                        <Text
                            textSize={{
                                xs: "display2",
                                md: "display3",
                            }}
                            textWeight="bold"
                            textAlign={{ lg: "center" }}
                            textColor="gunmetal"
                            m={{ t: { xs: "1rem", lg: "0.5rem" }, b: { xs: "2rem", lg: "0" } }}
                        >
                            Ci piace metterci <BoldText textColor="primary">la faccia</BoldText>.
                        </Text>
                        <SubheaderText
                            textAlign={{ lg: "center" }}
                            m={{ t: { md: "3rem" }, y: { xs: "1rem", md: "0" } }}
                        >
                            Anche se non è il massimo...
                        </SubheaderText>
                    </Container>
                </Div>

                <Div tag="section">
                    <Div m={{ t: { xs: "-1rem", lg: "-7rem" } }}>
                        <CoverWave className="wave" />
                    </Div>
                    <Container>
                        <Div bg="white" pos="relative" m={{ t: "1rem" }}>
                            <HeadingText>
                                Rullo di tamburi (<BoldText textColor="primary">da immaginare</BoldText>)
                            </HeadingText>
                            <SubheaderText>In ordine crescente di fotogenicità:</SubheaderText>
                        </Div>

                        <Div bg="white" pos="relative" m={{ b: "3rem" }}>
                            <MemberEntry
                                img={
                                    <StaticImage
                                        src="../images/foto-alberto-drusiani-ceo-squiseat.jpg"
                                        alt="Alberto Drusiani, CEO di Squiseat"
                                        placeholder="blurred"
                                        style={{
                                            borderRadius: "80px",
                                        }}
                                    />
                                }
                                name={
                                    <>
                                        Alberto Drusiani (<BoldText textColor="primary">Drus</BoldText>)
                                    </>
                                }
                                role="Quello che parla"
                                description={
                                    <>
                                        Vi basta conoscerlo una volta per assistere alla sua{" "}
                                        <BoldText>parlantina</BoldText> e nel caso le strade sono due: o rimanete
                                        incantati o <BoldText>scappate dalla sua logorrea</BoldText>. Se vi capita di
                                        incrociarlo fateci sapere che scelta avete fatto. Ad ogni modo è sempre
                                        disponibile a fare due chiacchiere con chiunque, se siete interessati scriveteci
                                        e <BoldText>preparatevi un piano B per la fuga</BoldText>.
                                    </>
                                }
                                alt="Alberto Drusiani di Squiseat"
                                align="left"
                            />

                            <MemberEntry
                                img={
                                    <StaticImage
                                        src="../images/foto-luca-morosini-cdo-squiseat.jpg"
                                        alt="Luca Morosini, CDO di Squiseat"
                                        placeholder="blurred"
                                        style={{
                                            borderRadius: "80px",
                                        }}
                                    />
                                }
                                name={
                                    <>
                                        Luca Morosini (<BoldText textColor="primary">Moros</BoldText>)
                                    </>
                                }
                                role="Il precisino"
                                description={
                                    <>
                                        La <BoldText>combinazione informatico-designer</BoldText> è pericolosissima:
                                        potete avere siti e app bellissimi da una parte o pomeriggi passati a capire
                                        quanti pixel dev'essere distante un'immagine dall'altra.{" "}
                                        <BoldText>Se trovate dei problemi</BoldText> di interfaccia nel sito o nell'app{" "}
                                        <BoldText>vi preghiamo di NON segnarceli</BoldText>, potremmo perderlo per
                                        diverse ore.
                                    </>
                                }
                                alt="Luca Morosini di Squiseat"
                                align="right"
                            />

                            <MemberEntry
                                img={
                                    <StaticImage
                                        src="../images/foto-gabriele-calarota-cto-squiseat.jpg"
                                        alt="Gabriele Calarota"
                                        placeholder="blurred"
                                        style={{
                                            borderRadius: "80px",
                                        }}
                                    />
                                }
                                name={
                                    <>
                                        Gabriele Calarota (<BoldText textColor="primary">Cala</BoldText>)
                                    </>
                                }
                                role="Lo script"
                                description={
                                    <>
                                        Leggende narrano che sia il <BoldText>top #1 sviluppatore Telegram</BoldText> in
                                        Italia. Non abbiamo mai verificato ma possiamo confermare che quello che
                                        sviluppa funziona molto bene. Uno dei suoi pregi migliori è la{" "}
                                        <BoldText>chiarezza</BoldText> quando parla di qualcosa di tecnico, così chiaro
                                        che abbiamo dovuto assumere un <BoldText>traduttore Cala-Italiano</BoldText>.
                                    </>
                                }
                                alt="Gabriele Calarota di Squiseat"
                                align="left"
                            />

                            <MemberEntry
                                img={
                                    <StaticImage
                                        src="../images/foto-ossama-gana-cfo-squiseat.jpg"
                                        alt="Ossama Gana, CFO di Squiseat"
                                        placeholder="blurred"
                                        style={{
                                            borderRadius: "80px",
                                        }}
                                    />
                                }
                                name={
                                    <>
                                        Ossama Gana (<BoldText textColor="primary">Osso</BoldText>)
                                    </>
                                }
                                role='"Ti serve un coupon?"'
                                description={
                                    <>
                                        Vi è mai capitato di dover acquistare qualcosa e voler{" "}
                                        <BoldText>trovare l'occasione</BoldText>? O di aver acquistato qualcosa e di
                                        dover fare un reclamo? Ecco, nel nostro team abbiamo uno dei massimo esponenti
                                        del settore, tanto che ormai se dobbiamo acquistare qualcosa, prima della
                                        conferma sorge sempre la domanda:
                                        <BoldText>"Osso hai un coupon?"</BoldText>. E la risposta è{" "}
                                        <BoldText>al 97% "Sì"</BoldText>.
                                    </>
                                }
                                alt="Ossama Gana di Squiseat"
                                align="right"
                            />
                        </Div>

                        <HeadingText textAlign={{ lg: "center" }} m={{ t: { xs: "5rem", md: "10rem" } }}>
                            Brutti eh? <BoldText textColor="primary">SCARICA L'APP!</BoldText>
                        </HeadingText>
                        <Div m={{ t: "2rem", x: " 0" }}>
                            <SubheaderText textAlign="center">
                                Non c'entra niente, ma sull'app <BoldText>non li vedrai mai</BoldText>, così potrai
                                riposare gli occhi!
                            </SubheaderText>

                            <Div d={{ md: "flex" }} justify="center">
                                <Anchor
                                    href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteIOS"
                                    id="download_ios_come_funziona"
                                    target="_blank"
                                    title="Scarica per iPhone | Squiseat"
                                >
                                    <Div
                                        w={{
                                            xs: "12.5rem",
                                            md: "15rem",
                                        }}
                                        m="1rem auto"
                                        p={{ x: "0.5rem" }}
                                    >
                                        <StaticImage
                                            src="../images/pulsante-download-app-store-ios.png"
                                            alt="Pulsante per il download su App Store iOS"
                                            placeholder="blurred"
                                        />
                                    </Div>
                                </Anchor>

                                <Anchor
                                    href="https://squiseat-subdomain.onelink.me/0GIO/WebsiteAndroid"
                                    id="download_android_come_funziona"
                                    target="_blank"
                                    title="Scarica per smartphone Android | Squiseat"
                                >
                                    <Div
                                        w={{
                                            xs: "12.5rem",
                                            md: "15rem",
                                        }}
                                        m="1rem auto"
                                        p={{ x: "0.5rem" }}
                                    >
                                        <StaticImage
                                            src="../images/pulsante-download-play-store-android.png"
                                            alt="Pulsante per il download su Play Store Android"
                                            placeholder="blurred"
                                        />
                                    </Div>
                                </Anchor>
                            </Div>
                        </Div>
                    </Container>
                </Div>
            </Div>
            <Footer />
        </ThemeProvider>
    )
}

export default Team
